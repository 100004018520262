<template>
    <div>
        <div class="section-content">
            <div class="container-fluid">
                <div class="row mb-4 align-items-center">
                    <div class="col-md-12 text-center">
                        <div class="about--spacer">
                            <hr/>
                            <div class="d-block">
                                <h3 class="title">Page is under maintenance</h3>
                                <router-link :class="'work-items'" :to="{name : 'home'}">
                                    <div class="title">Back to home page</div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MaintenanceView',
        metaInfo: {
            title: 'Tata Hijau Asri',
            titleTemplate: '%s - Maintenance'
        },
        mounted() {
            this.$parent.$parent.setCurrent('maintenance');
        }
    }
</script>