<template>
    <div>
        <div class="section-content section-spacer">
            <div class="container-fluid">
                <div class="row about-element-1 about-element-1--contact">
                    <img class="about-element-img-1" src="assets/images/element-3.png"/>
                    <div class="col-md-8 mb-4">
                        <div class="d-block pt-4 mb-2">
                            <div class="title title-md">MAPS</div>
                        </div>
                        
                        <div class="map-section">
                            <div class="gmap_canvas" v-html="$parent.$parent.dataProfile.maps">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-block pt-4">
                            <div class="title title-md mb-2">CONTACT</div>
                            <ul class="contact-list">
                                <li v-if="$parent.$parent.dataProfile.address">
                                    <div class="cl-title">ADDRESS</div>
                                    {{ $parent.$parent.dataProfile.address }}
                                </li>
                                <li v-if="$parent.$parent.dataProfile.phone">
                                    <div class="cl-title">PHONE</div>
                                    {{ $parent.$parent.dataProfile.phone }}
                                </li>
                                <li v-if="$parent.$parent.dataProfile.email">
                                    <div class="cl-title">EMAIL</div>
                                    <a class="link-social-media" :href="'mailto:'+$parent.$parent.dataProfile.email" target="_blank">
                                        {{$parent.$parent.dataProfile.email}}
                                    </a>
                                </li>
                                <li v-if="$parent.$parent.dataProfile.facebook_url">
                                    <div class="cl-title">FACEBOOK</div>
                                    <a class="link-social-media" :href="$parent.$parent.dataProfile.facebook_url" target="_blank">
                                        {{$parent.$parent.dataProfile.facebook_name}}
                                    </a>
                                </li>
                                <li v-if="$parent.$parent.dataProfile.instagram_url">
                                    <div class="cl-title">INSTAGRAM</div>
                                    <a class="link-social-media" :href="$parent.$parent.dataProfile.instagram_url" target="_blank">
                                        {{$parent.$parent.dataProfile.instagram_name}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ContactView',
        metaInfo: {
            title: 'Tata Hijau Asri',
            titleTemplate: '%s - Contact'
        },
        created() {
            console.log(this.$parent.$parent);
        }
    }
</script>