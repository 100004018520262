<template>
    <div>
        <img class="element-graphic-1 right-bottom" src="assets/images/element-2.png"/>
        <img class="element-graphic-1 left-top" src="assets/images/element-2.png"/>
        <div class="section-content section-spacer">
            <div class="container-fluid text-center" v-if="dataFetch">
                <p>Loading...</p>
            </div>
            
            <div class="container-fluid" v-if="!dataFetch">
                <div class="d-block w-100 mb-5">
                    <h1 class="section-title">Construction</h1>
                </div>
                <div class="row">
                    <!-- WORK ITEMS -->
                    <div class="col-md-4 mb-4" v-for="(item, index) in dataList" :key="index">
                        <router-link :class="'work-items'" :to="{name : 'works_construction_detail', params: {slug : item.code}}">
                            <div class="work-items--inner"
                                v-bind:style="{ 'background-image': 'url(' + item.thumbnail + ')' }">
                            </div>
                            <div class="work-items--title d-flex h-100 w-100 align-items-center justify-content-center">
                                <h3 class="mb-0" :data-city="item.location">{{ item.name }}</h3>
                            </div>
                        </router-link>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'WorksConstructionView',
        metaInfo: {
            title: 'Tata Hijau Asri',
            titleTemplate: '%s - Construction'
        },
        data() {
            return {
                dataFetch   : true,
                dataList    : [],
            }
        },
        mounted() {
            this.$parent.$parent.setCurrent('works_construction');
        },
        created() {
            this.getData();
        },
        methods: {
            getExternalScript: function () {
                let externalScript = document.createElement('script')
                externalScript.setAttribute('src', 'assets/js/custom.js')
                document.head.appendChild(externalScript)
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'website/works/list';

                this.dataFetch  = true;                
                this.$http.get(uri, {
                    params: {
                        works_type: 'construction'
                    }
                }).then(res => {
                    this.dataFetch  = false;                
                    this.dataList   = res.data.data;
                    this.getExternalScript();
                });
            }
        }
    }
</script>