<template>
    <div>
        <div class="section-content">
            <img class="about-element-img-1 ae-services" src="assets/images/element-3.png"/>
            <img class="about-element-img-3" src="assets/images/element-3.png"/>
            <img class="about-element-img-2" src="assets/images/element-3-x.png"/>
            <div class="container-fluid text-center" v-if="dataFetch">
                <p>Loading...</p>
            </div>
            <div class="container-fluid" v-if="!dataFetch">
                <!-- SERVICES: CONSTRUCTION -->
                <div class="row align-items-center mb-5">
                    <div class="col-md-8">
                        <div class="slider-image-content">
                            <div v-if="dataList['construction'].banner_one" class="slider-image-content--item" v-bind:style="{ 'background-image': 'url(' + dataList['construction'].banner_one + ')' }"></div>
                            <div v-if="dataList['construction'].banner_two" class="slider-image-content--item" v-bind:style="{ 'background-image': 'url(' + dataList['construction'].banner_two + ')' }"></div>
                            <div v-if="dataList['construction'].banner_three" class="slider-image-content--item" v-bind:style="{ 'background-image': 'url(' + dataList['construction'].banner_three + ')' }"></div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="about--spacer about--spacer-small">
                            <div class="d-block">
                                <div class="title title-md">CONSTRUCTION</div>
                                <div v-html="dataList['construction'].description"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- -->
                <!-- SERVICES: MAINTENANCE -->
                <div class="row align-items-center mb-5">
                    <div class="col-md-8 order-md-last">
                        <div class="slider-image-content">
                            <div v-if="dataList['maintenance'].banner_one" class="slider-image-content--item" v-bind:style="{ 'background-image': 'url(' + dataList['maintenance'].banner_one + ')' }"></div>
                            <div v-if="dataList['maintenance'].banner_two" class="slider-image-content--item" v-bind:style="{ 'background-image': 'url(' + dataList['maintenance'].banner_two + ')' }"></div>
                            <div v-if="dataList['maintenance'].banner_three" class="slider-image-content--item" v-bind:style="{ 'background-image': 'url(' + dataList['maintenance'].banner_three + ')' }"></div>                            
                        </div>
                    </div>
                    <div class="col-md-4 order-md-first">
                        <div class="about--spacer about--spacer-small">
                            <div class="d-block">
                                <div class="title title-md">MAINTENANCE</div>
                                <div v-html="dataList['maintenance'].description"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- -->
                <!-- SERVICES: RENTAL -->
                <div class="row align-items-center mb-5">
                    <div class="col-md-8">
                        <div class="slider-image-content">
                            <div v-if="dataList['rental'].banner_one" class="slider-image-content--item" v-bind:style="{ 'background-image': 'url(' + dataList['rental'].banner_one + ')' }"></div>
                            <div v-if="dataList['rental'].banner_two" class="slider-image-content--item" v-bind:style="{ 'background-image': 'url(' + dataList['rental'].banner_two + ')' }"></div>
                            <div v-if="dataList['rental'].banner_three" class="slider-image-content--item" v-bind:style="{ 'background-image': 'url(' + dataList['rental'].banner_three + ')' }"></div>                            
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="about--spacer about--spacer-small">
                            <div class="d-block">
                                <div class="title title-md">RENTAL</div>
                                <div v-html="dataList['rental'].description"></div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ServicesView',
        metaInfo: {
            title: 'Tata Hijau Asri',
            titleTemplate: '%s - Services'
        },
        data() {
            return {
                dataFetch   : true,
                dataList    : [],
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getExternalScript: function () {
                let externalScript = document.createElement('script')
                externalScript.setAttribute('src', 'assets/js/custom.js')
                document.head.appendChild(externalScript)
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'website/services/list';

                this.dataFetch  = true;                
                this.$http.get(uri).then(res => {
                    this.dataFetch  = false;                
                    this.dataList   = res.data.data;
                    this.getExternalScript();
                });
            },
        }
    }
</script>