<template>
  <div id="app">
    <div class="main-navigation " :class="activeClass == 'home' ? 'main-navigation--fixed':''">
        <div class="main-navigation--inner d-flex h-100 justify-content-center">
            <div class="main-logo">
                <router-link :to="{name : 'home'}" @click.native="setCurrent('home')">
                    <img :src="`${publicPath}assets/images/tatahijauasri-logo.png`"/>
                </router-link>
            </div>
            <div class="nav-list">
                <ul>
                    <li>
                        <router-link :active-class="activeClass == 'about' ? 'active':''" :to="{name : 'about'}" @click.native="setCurrent('about')">ABOUT US</router-link>
                    </li>
                    <li>
                        <router-link :active-class="activeClass == 'services' ? 'active':''" :to="{name : 'services'}" @click.native="setCurrent('services')">SERVICES</router-link>
                    </li>
                    <li>
                        <div class="dropdown">
                            <a :class="activeClass == 'works_construction' || activeClass == 'works_maintenance' || activeClass == 'works_construction_detail' || activeClass == 'works_maintenance_detail'? 'active':''" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                WORKS
                            </a>
                            <div class="dropdown-menu shadow-lg border-0" aria-labelledby="dropdownMenuLink">
                                <router-link class="active active-type-2 dropdown-item" :active-class="activeClass == 'works_construction' || activeClass == 'works_construction_detail' ? 'active':''" :to="{name : 'works_construction'}" @click.native="setCurrent('works_construction')">Construction</router-link>
                                <router-link class="active active-type-2 dropdown-item" :active-class="activeClass == 'works_maintenance' || activeClass == 'works_maintenance_detail' ? 'active':''" :to="{name : 'works_maintenance'}" @click.native="setCurrent('works_maintenance')">Maintenance</router-link>
                            </div>
                        </div>
                    </li>
                    <li>
                        <router-link :active-class="activeClass == 'contact' ? 'active':''" :to="{name : 'contact'}" @click.native="setCurrent('contact')">CONTACTS</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="nav-toggle">
        <div class="nav-toggle-icon"></div>
    </div>
    <div class="main-navigation--mobile">
        <div class="main-navigation--mobile--inner">
            <ul>
                <li>
                    <a href="about-us">ABOUT US</a>
                </li>
                <li>
                    <a href="services">SERVICES</a>
                </li>
                <li>
                    <a href="works-construction">CONSTRUCTION</a>
                </li>
                <li>
                    <a href="works-maintenance">MAINTENANCE</a>
                </li>
                <li>
                    <a href="contact">CONTACT</a>
                </li>
            </ul>
        </div>
    </div>
    <slot/>
    <footer :class="activeClass == 'home' || activeClass == 'maintenance' || activeClass == 'works_construction_detail' || activeClass == 'works_maintenance_detail' ? 'hide-this':''">
        <img class="footer-element fe-left" :src="`${publicPath}assets/images/footer-element-left.png`"/>
        <img class="footer-element fe-right" :src="`${publicPath}assets/images/footer-element-right.png`"/>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-3 col-sm-12">
                    <div class="font-small">
                        ©2019 TATA HIJAU ASRI
                    </div>
                </div>
                <div class="col-md-9 col-sm-12 text-right">
                    <ul class="footer-list">
                        <li v-if="dataProfile.phone">
                            <img :src="`${publicPath}assets/images/icon-phone.png`">
                            {{  dataProfile.phone}}
                        </li>

                        <li v-if="dataProfile.email">
                            <a class="link-social-media" :href="'mailto:'+dataProfile.email" target="_blank"><img :src="`${publicPath}assets/images/icon-mail.png`">
                            {{dataProfile.email}}
                            </a>
                        </li>
                        
                        <li v-if="dataProfile.facebook_name">
                            <a class="link-social-media" :href="dataProfile.facebook_url" target="_blank"><img :src="`${publicPath}assets/images/icon-facebook.png`">
                            {{dataProfile.facebook_name}}
                            </a>
                        </li>

                        <li v-if="dataProfile.instagram_name">
                            <a class="link-social-media" :href="dataProfile.instagram_url" target="_blank"><img :src="`${publicPath}assets/images/icon-instagram.png`">
                            {{dataProfile.instagram_name}}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
  </div>
</template>
<script>
    export default {
        name: 'MainLayout',
        data() {
            return {
                activeClass : this.$route.name,
                publicPath  : process.env.BASE_URL,
                dataFetch   : true,
                dataProfile  : [],
            }
        },
        created() {
            this.getData();
        },
        methods: {
            setCurrent: function(params = "") {
                this.activeClass    = params;
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'website/profile';

                this.dataFetch  = true;                
                this.$http.get(uri).then(res => {
                    this.dataFetch      = false;                
                    this.dataProfile    = res.data.data;
                });
            },
        }
    }
</script>
