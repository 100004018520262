import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import VueBodyClass from 'vue-body-class';

import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Services from '../views/Services.vue'
import WorksConstruction from '../views/WorksConstruction.vue'
import WorksConstructionDetail from '../views/WorksConstructionDetail.vue'
import WorksMaintenance from '../views/WorksMaintenance.vue'
import WorksMaintenanceDetail from '../views/WorksMaintenanceDetail.vue'
import Contact from '../views/Contact.vue'
import Maintenance from '../views/Maintenance.vue'

import MainLayout from '../layout/MainLayout.vue'

Vue.use(VueRouter)
Vue.use(VueMeta);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      layout : MainLayout,
      transition: 'fade',
      bodyClass: 'no-padding-bottom'
    }
  },
  {
    path: '/about-us',
    name: 'about',
    component: About,
    meta: {
      layout : MainLayout,
      transition: 'fade'
    }
  },
  {
    path: '/services',
    name: 'services',
    component: Services,
    meta: {
      layout : MainLayout,
      transition: 'fade'
    }
  },
  {
    path: '/works-construction',
    name: 'works_construction',
    component: WorksConstruction,
    meta: {
      layout : MainLayout,
      transition: 'fade'
    }
  },
  {
    path: '/works-construction-detail/:slug',
    name: 'works_construction_detail',
    component: WorksConstructionDetail,
    meta: {
      layout : MainLayout,
      transition: 'fade',
      bodyClass: 'no-padding-bottom'
    }
  },
  {
    path: '/works-maintenance',
    name: 'works_maintenance',
    component: WorksMaintenance,
    meta: {
      layout : MainLayout,
      transition: 'fade'
    }
  },
  {
    path: '/works-maintenance-detail/:slug',
    name: 'works_maintenance_detail',
    component: WorksMaintenanceDetail,
    meta: {
      layout : MainLayout,
      transition: 'fade',
      bodyClass: 'no-padding-bottom'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: {
      layout : MainLayout,
      transition: 'fade',
      bodyClass: 'body-contact'
    }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: Maintenance,
    meta: {
      layout : MainLayout,
      transition: 'fade',
      bodyClass: 'no-padding-bottom'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }  
})

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => { vueBodyClass.guard(to, next) });
 
export default router