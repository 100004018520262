<template>
    <div>
        <div class="works-detail-container text-center" v-if="dataFetch">
            <p>Loading...</p>
        </div>
        <div class="works-detail-container" v-if="!dataFetch">
            <div id="slide1" class="works-detail-group slide-one">
                <div
                    class="full-image-left"
                    v-bind:style="{ 'background-image': 'url(' + dataDetail.banner_one + ')' }">
                </div>
                <div class="full-image-right">
                    <div
                        class="second-image"
                        v-bind:style="{ 'background-image': 'url(' + dataDetail.banner_two + ')' }">
                    </div>

                    <div class="work-detail--content">
                        <div class="work-detail--title">
                            {{ dataDetail.name }}
                        </div>
                        <div class="city">
                            {{ dataDetail.location }}
                        </div>
                        <ul class="history-detail">
                            <li>Klien: <span>{{ dataDetail.client }}</span></li>
                            <li>Lokasi: <span>{{ dataDetail.location }}</span></li>
                            <li>Tipe: <span>{{ dataDetail.transaction_type }}</span></li>
                            <li>Status: <span>{{ dataDetail.transaction_status }}</span></li>
                        </ul>
                        <div class="d-flex align-items-center justify-content-between w-100">
                            <router-link class="btn-work" :to="{name : 'works_maintenance'}">
                                <img :src="`${publicPath}assets/images/icon-btn-prev.png`"/>
                                Back to Maintenance
                            </router-link>

                            <button v-if="dataDetail.banner_three || dataDetail.banner_four" id="moveRight" class="btn-work btn-next-prev">
                                Next
                                <img :src="`${publicPath}assets/images/icon-btn-next.png`"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="slide2" class="works-detail-group slide-two">
                <div
                    class="full-image-left"
                    v-bind:style="{ 'background-image': 'url(' + dataDetail.banner_three + ')' }">
                </div>
                <div class="full-image-right">
                    <div
                        class="second-image"
                        v-bind:style="{ 'background-image': 'url(' + dataDetail.banner_four + ')' }">
                    </div>
                    <div class="work-detail--content">
                        <div class="work-detail--title">
                            {{ dataDetail.name }}
                        </div>
                        <div class="city">
                            {{ dataDetail.location }}
                        </div>
                        <ul class="history-detail">
                            <li>Klien: <span>{{ dataDetail.client }}</span></li>
                            <li>Lokasi: <span>{{ dataDetail.location }}</span></li>
                            <li>Tipe: <span>{{ dataDetail.transaction_type }}</span></li>
                            <li>Status: <span>{{ dataDetail.transaction_status }}</span></li>
                        </ul>
                        <div class="d-flex align-items-center justify-content-between w-100">
                            <router-link class="btn-work" :to="{name : 'works_maintenance'}">
                                <img :src="`${publicPath}assets/images/icon-btn-prev.png`"/>
                                Back to Maintenance
                            </router-link>
                            <button id="moveLeft" class="btn-work btn-next-prev">
                                Previous
                                <img :src="`${publicPath}assets/images/icon-btn-prev.png`"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'WorksMaintenanceView',
        metaInfo: {
            title: 'Tata Hijau Asri',
            titleTemplate: '%s - Maintenance'
        },
        data() {
            return {
                dataFetch   : true,
                dataDetail  : [],
                publicPath  : process.env.BASE_URL
            }
        },
        mounted() {
            this.$parent.$parent.setCurrent('works_maintenance_detail');
        },
        created() {
            this.getData();
        },
        methods: {
            getExternalScript: function () {
                let externalScript = document.createElement('script')
                externalScript.setAttribute('src', '../assets/js/custom.js')
                document.head.appendChild(externalScript)
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'website/works/detail';

                this.dataFetch  = true;                
                this.$http.get(uri, {
                    params: {
                        slug: this.$route.params.slug
                    }
                }).then(res => {
                    this.dataFetch  = false;                
                    this.dataDetail = res.data.data;
                    this.getExternalScript();
                });
            }
        }
    }
</script>