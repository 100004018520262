<template>
    <div>
        <div class="section-content">
            <img class="about-element-img-1" src="assets/images/element-3.png"/>
            <img class="about-element-img-2" src="assets/images/element-3-x.png"/>
            <div class="container-fluid">
                <div class="row mb-4 align-items-center">
                    <div class="col-md-8 mb-4" v-if="$parent.$parent.dataProfile.cover_picture">
                        <img class="d-block w-100" :src="$parent.$parent.dataProfile.cover_picture"/>
                    </div>
                    
                    <div class="col-md-8 mb-4" v-if="!$parent.$parent.dataProfile.cover_picture">
                        <img class="d-block w-100" src="assets/images/about-image-1.jpg"/>
                    </div>

                    <div class="col-md-4">
                        <div class="about--spacer">
                            <div class="d-block">
                                <div class="title title-md">STORY IN BRIEF</div>
                                <div v-html="$parent.$parent.dataProfile.story"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row mb-4 justify-content-md-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="title title-md">VISION</div>
                        <div v-html="$parent.$parent.dataProfile.vision"></div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="title title-md">MISSION</div>
                        <div v-html="$parent.$parent.dataProfile.mission"></div>
                    </div>
                </div>
                <div class="row justify-content-md-center">
                    <div class="col-lg-12 mb-4">
                        <div class="d-block w-100 text-center pt-4 pb-4">
                            <div class="title title-lg">CORE VALUES</div>
                            <div v-html="$parent.$parent.dataProfile.core_values_description"></div>
                        </div>

                        <div v-if="dataFetchItem" class="d-block text-center">
                            <p>Loading...</p>
                        </div>

                        <div v-if="!dataFetchItem" class="row">
                            <div v-for="(item, index) in dataListItem" :key="index" class="col-md-6 mb-3">
                                <h4 class="title-list">{{ item.name }}</h4>
                                <div v-html="item.description"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-md-center">
                    <div class="col-lg-12 mb-4">
                        <div class="d-block w-100 text-center pt-4 pb-4">
                            <div class="title title-lg mb-4">TEAM</div>
                            <img v-if="$parent.$parent.dataProfile.team_picture" class="d-block w-100 mb-5" :src="$parent.$parent.dataProfile.team_picture"/>
                            <img v-if="!$parent.$parent.dataProfile.team_picture" class="d-block w-100 mb-5" src="assets/images/team-group.jpg"/>

                            <div v-if="dataFetch" class="d-block">
                                <p>Loading...</p>
                            </div>

                            <div v-if="!dataFetch">
                                <div v-for="(item, index) in dataList" :key="index" class="d-block">
                                    <div class="team-group">
                                        <div class="team-group--list" v-for="(itemDetail, indexDetail) in item" :key="indexDetail">
                                            <h4 class="title-list mb-4">{{ itemDetail.position_name }}</h4>
                                            
                                            <div class="team-group--list--photo">
                                                <div class="team-list" v-for="(team, indexTeam) in itemDetail.team_list" :key="indexTeam">
                                                    <div class="team-list--image"
                                                    v-bind:style="{ 'background-image': 'url(' + team.picture + ')' }"
                                                    ></div>
                                                    <div class="team-list--name">
                                                        {{ team.name }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AboutView',
        metaInfo: {
            title: 'Tata Hijau Asri',
            titleTemplate: '%s - About Us'
        },
        data() {
            return {
                dataFetch   : true,
                dataList    : [],
                dataFetchItem   : true,
                dataListItem    : [],
            }
        },
        created() {
            this.getData();
            this.getCoreValues();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'website/team/list';

                this.dataFetch  = true;                
                this.$http.get(uri).then(res => {
                    this.dataFetch  = false;                
                    this.dataList   = res.data.data;
                });
            },
            getCoreValues: function() {
                let uri         = process.env.VUE_APP_APIHOST+'website/core-values-item';

                this.dataFetchItem  = true;                
                this.$http.get(uri).then(res => {
                    this.dataFetchItem  = false;                
                    this.dataListItem   = res.data.data;
                });
            },
        }                
    }
</script>