<template>
    <div>
        <div class="front-banner" v-if="!dataFetch">
            <div v-for="(item, index) in dataList" :key="index"
                class="front-banner--items"
                v-bind:style="{ 'background-image': 'url(' + item.picture + ')' }">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HomeView',
        metaInfo: {
            title: 'Tata Hijau Asri',
            titleTemplate: '%s - Official Website'
        },
        data() {
            return {
                dataFetch   : true,
                dataList    : [],
            }
        },
        mounted() {
            this.getExternalScript();
        },
        created() {
            this.getData();
        },
        methods: {
            getExternalScript: function () {
                let externalScript = document.createElement('script')
                externalScript.setAttribute('src', 'assets/js/custom.js')
                document.head.appendChild(externalScript)
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'website/banner/list';

                this.dataFetch  = true;                
                this.$http.get(uri).then(res => {
                    this.dataFetch  = false;                
                    this.dataList   = res.data.data;
                });
            },
        }
    }
</script>